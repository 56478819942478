/* eslint-disable no-undef */

const blockName = 'disable-page-scroll'
const blockNameIOS = 'disable-page-scroll_ios'

const isIOS = typeof navigator !== 'undefined' && /iPad|iPhone|iPod/.test(navigator.userAgent)

let scrollX = 0
let scrollY = 0

export function disablePageScroll() {
  if (typeof document !== 'undefined') {
    const { body } = document

    if (body.classList.value.includes(blockName)) {
      return
    }

    body.classList.add(blockName)

    if (isIOS) {
      scrollX = window.scrollX
      scrollY = window.scrollY

      body.classList.add(blockNameIOS)
    }
  }
}

export function enablePageScroll() {
  if (typeof document !== 'undefined') {
    const { body } = document
    body.classList.remove(blockName)

    if (isIOS) {
      body.classList.remove(blockNameIOS)

      window.scrollTo(scrollX, scrollY)
    }
  }
}

export function isPageScrollLocked() {
  if (typeof document !== 'undefined') {
    const { body } = document
    return body.classList.contains(blockName)
  }

  return false
}
