export function buildNotifications({ user, has10PercentOff, hasWelcomeOffer }) {
  const notifications = []

  if (user?.creditsTotal && parseFloat(user.creditsTotal.replace(/[^\d.]/g, '')) > 0) {
    notifications.push({
      firstName: user?.firstName,
      typeLabel: 'Store Credit',
      type: 'store_credit',
      formattedAmount: user?.creditsTotal,
    })
  }

  if (has10PercentOff) {
    notifications.push({
      text: '10% off full-priced items',
      type: 'new_user',
      formattedAmount: '10%',
    })
  }

  if (hasWelcomeOffer) {
    notifications.push({
      text: '15% off full-priced items',
      type: 'new_user',
      formattedAmount: '15%',
    })
  }

  return notifications
}

export function buildCartNotifications(adjustments = []) {
  const notificationTypes = []

  adjustments.forEach(adj => {
    if (adj.label.includes('Discount')) {
      notificationTypes.push('discount')
    } else if (adj.label === 'Credit') {
      notificationTypes.push('store_credit--checkout')
    }
  })

  return [...new Set(notificationTypes)].map(notification => ({ type: notification }))
}
